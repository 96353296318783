$contrast: mat.m2-get-color-from-palette($primary, 'A100');
$primaryColor: mat.m2-get-color-from-palette($primary, 500);
$background: mat.m2-get-color-from-palette($primary, 100);
$panel: mat.m2-get-color-from-palette($primary, 'A100');
$active: mat.m2-get-color-from-palette($primary, 600);
$disabled: mat.m2-get-color-from-palette($primary, 700);
$accentLight: mat.m2-get-color-from-palette($accent, 100);
$accentDark: mat.m2-get-color-from-palette($accent, 900);
$warnLight: mat.m2-get-color-from-palette($warn, 50);
$text: mat.m2-get-contrast-color-from-palette($primary, 'A100');
$contrastText: mat.m2-get-contrast-color-from-palette($primary, 500);

// Drawer
.mat-drawer-container.drawer-container {
  overflow: visible;
}

html {
  --mat-sidenav-container-background-color: #{$panel};
  --mat-sidenav-content-background-color: #{$background};
}

// Table
.mat-mdc-table .mat-mdc-row:hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: var(--resto-primary-50);
}

.mat-mdc-table .mdc-data-table__header-cell {
  font-weight: 500;
  --mdc-typography-subtitle2-font-size: 1rem;
}

.mat-mdc-table .mdc-data-table__cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

// Cards
.mat-mdc-card {
  --mdc-elevated-card-container-color: #{$background};
}

// Buttons
:root {
  .mat-mdc-unelevated-button:not(:disabled) {
    color: $contrastText;
  }
  --mdc-icon-button-state-layer-size: 32px;
  --mdc-icon-button-icon-size: 18px;
  --mdc-typography-button-letter-spacing: mat.letter-spacing(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-family: mat.font-family(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.font-size(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.line-height(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.font-weight(
    $custom-typography,
    'button'
  );
}

// Form fields
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
  // BUGFIX height (align with selects)
  padding-bottom: 6px !important;
}
.mat-mdc-form-field-type-mat-select:not(.mat-mdc-paginator-page-size-select) {
  // BUGFIX alignment (align with regular fields)
  position: relative;
  top: -2px;
}

// Checkbox
.mat-mdc-checkbox .mdc-form-field {
  --mdc-checkbox-selected-checkmark-color: #{$contrast};
}

// Snackbar
.mat-mdc-snack-bar-container.success-snack .mdc-snackbar__surface {
  --mdc-snackbar-container-color: #{$accentLight};
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  --mdc-snackbar-supporting-text-color: #{$text};
}

.mat-mdc-snack-bar-container.error-snack .mdc-snackbar__surface {
  --mdc-snackbar-container-color: #{$warnLight};
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-mdc-snack-bar-button-color: #{$accentDark};
}

// Toggle Group
.mat-button-toggle {
  font-family: $font;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
}

.mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: 0;
}

// Density classes
.field-density-1 {
  @include mat.form-field-density(-1);
}

.field-density-3 {
  @include mat.form-field-density(-3);
}

.field-density-5 {
  @include mat.form-field-density(-5);
}

.button-toggle-density-1 {
  @include mat.button-toggle-density(-1);
}

.button-toggle-density-3 {
  @include mat.button-toggle-density(-3);
}

.button-density-1 {
  @include mat.button-density(-1);
}

.button-density-3 {
  @include mat.button-density(-3);

  .mdc-button {
    font-weight: 400;
  }
}

.icon-button-density-1 {
  @include mat.icon-button-density(-1);
  @include mat.icon-density(-1);
}

.icon-button-density-3 {
  @include mat.icon-button-density(-3);

  .mat-icon {
    width: 18px;
    font-size: 18px;
    line-height: 24px;
  }
}

// Sticky tables
thead[role='rowgroup'],
.group-header {
  position: sticky;
  top: 0;
  z-index: 9;
}

// Density classes
.icon-button-density-3 {
  @include mat.icon-button-density(-3);

  .mat-icon {
    width: 18px;
    font-size: 18px;
    line-height: 24px;
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 18px;
  }

  .mat-select-search-clear {
    top: 5px !important;
  }
}
