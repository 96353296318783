/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@font-face {
  font-family: 'Dubai';
  src: url('/assets/fonts/dubaiw23-light.eot');
  src:
    url('/assets/fonts/dubaiw23-light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/dubaiw23-light.woff2') format('woff2'),
    url('/assets/fonts/dubaiw23-light.woff') format('woff'),
    url('/assets/fonts/dubaiw23-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src: url('/assets/fonts/dubaiw23-regular.eot');
  src:
    url('/assets/fonts/dubaiw23-regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/dubaiw23-regular.woff2') format('woff2'),
    url('/assets/fonts/dubaiw23-regular.woff') format('woff'),
    url('/assets/fonts/dubaiw23-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src: url('/assets/fonts/dubaiw23-medium.eot');
  src:
    url('/assets/fonts/dubaiw23-medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/dubaiw23-medium.woff2') format('woff2'),
    url('/assets/fonts/dubaiw23-medium.woff') format('woff'),
    url('/assets/fonts/dubaiw23-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src: url('/assets/fonts/dubaiw23-bold.eot');
  src:
    url('/assets/fonts/dubaiw23-bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/dubaiw23-bold.woff2') format('woff2'),
    url('/assets/fonts/dubaiw23-bold.woff') format('woff'),
    url('/assets/fonts/dubaiw23-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

router-outlet + * {
  width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  height: 100%;
  background-color: var(--resto-primary-100);
}
