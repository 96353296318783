@function customTypography($scale) {
  $result: mat.m2-define-typography-config(
    $headline-5:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'headline-5'),
        $scale * mat.m2-line-height($custom-typography, 'headline-5'),
        mat.m2-font-weight($custom-typography, 'headline-5'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'headline-5')
      ),
    $headline-6:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'headline-6'),
        $scale * mat.m2-line-height($custom-typography, 'headline-6'),
        mat.m2-font-weight($custom-typography, 'headline-6'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'headline-6')
      ),
    $subtitle-1:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'subtitle-1'),
        $scale * mat.m2-line-height($custom-typography, 'subtitle-1'),
        mat.m2-font-weight($custom-typography, 'subtitle-1'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'subtitle-1')
      ),
    $subtitle-2:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'subtitle-2'),
        $scale * mat.m2-line-height($custom-typography, 'subtitle-2'),
        mat.m2-font-weight($custom-typography, 'subtitle-2'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'subtitle-2')
      ),
    $body-1:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'body-1'),
        $scale * mat.m2-line-height($custom-typography, 'body-1'),
        mat.m2-font-weight($custom-typography, 'body-1'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'body-1')
      ),
    $body-2:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'body-2'),
        $scale * mat.m2-line-height($custom-typography, 'body-2'),
        mat.m2-font-weight($custom-typography, 'body-2'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'body-2')
      ),
    $caption:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'caption'),
        $scale * mat.m2-line-height($custom-typography, 'caption'),
        mat.m2-font-weight($custom-typography, 'caption'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'caption')
      ),
    $button:
      mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'button'),
        $scale * mat.m2-line-height($custom-typography, 'button'),
        mat.m2-font-weight($custom-typography, 'button'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'button')
      ),
  );
  @return $result;
}

.font-size-125 {
  @include mat.all-component-typographies(customTypography(1.25));
}

.font-size-150 {
  @include mat.all-component-typographies(customTypography(1.5));
}
